<template>
  <div>
    <siteHeader />

    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow">
            <div class="card-header bg-transparent">
              <h3 class="mb-0">Auction</h3>
            </div>
            <div class="card-body">
              <div class="row1">
                <card shadow type="secondary">
                  <form>
                    <div class="pl-lg-4">
                      <div class="row">
                        <div class="col-md-6">
                          <base-input
                            alternative=""
                            placeholder="Auction Template Name"
                            label="Auction Template Name"
                            input-classes="form-control-alternative"
                            :value="model.auction_template_name"
                            @input="
                              (event) =>
                                (model.auction_template_name =
                                  event.target.value)
                            "
                          />
                          <div
                            class="error mb-2"
                            v-if="v$.model.auction_template_name.$error"
                          >
                            Auction Template Name is required.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row1">
                      <div class="table-responsive">
                        <base-table
                          class="table align-items-center table-flush"
                          :class="type === 'dark' ? 'table-dark' : ''"
                          :thead-classes="
                            type === 'dark' ? 'thead-dark' : 'thead-light'
                          "
                          :key="index"
                          tbody-classes="list"
                          :data="rowData"
                        >
                          <template v-slot:columns>
                            <th>Item</th>
                            <th>
                              <i
                                class="ni ni-fat-add text-green"
                                style="font-size: large"
                                v-on:click="addRow()"
                              >
                              </i>
                            </th>
                          </template>

                          <template v-slot:default="row">
                            <td>
                              <base-input
                                alternative=""
                                input-classes="form-control-alternative"
                                class="col-md-6"
                              >
                                <el-select
                                  class="col-md-6"
                                  input-classes="form-control-alternative"
                                  placeholder=""
                                  filterable
                                  v-model="row.item.item_id"
                                  :value="row.item.item_id"
                                >
                                  <el-option
                                    v-for="option in itemData"
                                    class="select-danger"
                                    :value="option.id"
                                    :label="option.item_name"
                                    :key="option.id"
                                  >
                                  </el-option>
                                </el-select>
                              </base-input>
                            </td>
                            <td>
                              <i
                                class="ni ni-fat-delete text-red"
                                style="font-size: large"
                                v-on:click="deleteRow(index)"
                              >
                              </i>
                            </td>
                          </template>
                        </base-table>
                      </div>
                    </div>
                    <div class="row1">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customCheck1"
                          v-model="model.is_active"
                          false-value="2"
                          true-value="1"
                        />
                        <label class="custom-control-label" for="customCheck1"
                          >Active?</label
                        >
                      </div>
                    </div>
                    <div class="row" v-if="id.data == 'undefined'">
                      <div class="col-md-5"></div>
                      <div class="col-md-4">
                        <button
                          type="button"
                          class="btn btn-default"
                          v-on:click="create()"
                        >
                          Create
                        </button>
                      </div>
                    </div>
                    <div class="row" v-if="id.data != 'undefined'">
                      <div class="col-md-5"></div>
                      <div class="col-md-4">
                        <button
                          type="button"
                          class="btn btn-default"
                          v-on:click="Update()"
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </form>
                  <br />
                  <div v-if="isLoading">
                    <base-alert type="success" dismissible>
                      <span class="alert-inner--text"
                        ><strong>Success!</strong> Record Created
                        Successfully</span
                      >
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </base-alert>
                  </div>
                  <div v-if="isUpdateLoading">
                    <base-alert type="success" dismissible>
                      <span class="alert-inner--text"
                        ><strong>Success!</strong> Record Updated
                        Successfully</span
                      >
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </base-alert>
                  </div>
                </card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElSelect, ElOption } from "element-plus";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
const auctiontemplateRepository = RepositoryFactory.get("auction_template");
const memberRepository = RepositoryFactory.get("member");
const itemRepository = RepositoryFactory.get("item");
export default {
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
  },
  name: "Auction",
  data() {
    return {
      v$: useVuelidate(),
      id: {},
      isLoading: false,
      hide: false,
      isUpdateLoading: false,
      rowData: [{}],
      memberData: [{}],
      itemData: [{}],
      model: {},
    };
  },

  validations() {
    return {
      model: {
        auction_template_name: { required },
      },
    };
  },
  methods: {
    async create() {
      this.v$.$validate(); // checks all inputs
      if (this.v$.$error) {
        return;
      }
      if (this.model.is_active != 1) {
        this.model.is_active = "2";
      }

      this.model.auctiontempadd = JSON.stringify(this.rowData);
      let data = RepositoryFactory.JSON_to_URLEncoded(this.model, "data", []);
      let response = await auctiontemplateRepository.create(data);
      if (response.data.status == 100) {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
          this.$router.push("/auction/view_auction_template");
        }, 1000);
      }
    },
    async Update() {
      this.model.auctiontempadd = this.rowData;
      let data = RepositoryFactory.JSON_to_URLEncoded(this.model, "data", []);
      let response = await auctiontemplateRepository.create(data);
      if (response.data.status == 101) {
        this.isUpdateLoading = true;
        setTimeout(() => {
          this.isUpdateLoading = false;
          this.$router.push("/auction/view_auction_template");
        }, 1000);
      }
    },

    addRow() {
      this.rowData.push({});
    },
    deleteRow(i) {
      this.rowData.splice(i, 1);
    },
    getList() {
      memberRepository.getMember().then((data) => {
        this.tableData = Object.freeze(data);
        this.memberData = this.tableData.data;
      });
    },
    getAuctionList() {
      itemRepository.getAuction().then((data) => {
        this.tableData = Object.freeze(data);
        this.itemData = this.tableData.data;
      });
    },
  },
  mounted() {
    this.id = this.$route.params;
    this.model = {};
    if (this.id.data != "undefined") {
      auctiontemplateRepository
        .getAuctionTemplateByid(this.id.data)
        .then((data) => {
          if (data.data.length != 0) {
            this.model = data.data[0];
            // this.rowData = data.data[0].auctiondata;
            this.rowData = Object.assign([], data.data[0].auctiontempadd);
          }
        });
    }

    this.getList();
    this.getAuctionList();
  },
};
</script>

<style></style>
